import { createI18n } from "vue-i18n";
// 匯入所有語言 JSON 檔案
import zhTw from "./locales/zh_tw.json";
import enUs from "./locales/en_us.json";
import jaJp from "./locales/ja_jp.json";
import zhCn from "./locales/zh-cn.json";
// 設定語言資料
const messages = {
	zh_tw: zhTw,
	zh_cn: zhCn,
	en_us: enUs,
	ja_jp: jaJp,
};
// 從 localStorage 讀取語系，如果沒有則預設繁體中文
const savedLocale = localStorage.getItem("locale") || "zh_tw";

// 建立 Vue I18n 實例
const i18n = createI18n({
	legacy: false,
	locale: savedLocale, //en_us zh_tw
	fallbackLocale: "zh_tw",
	messages: messages,
});

export default i18n;
